import './App.css';
import {useEffect, useState} from "react";

// const API_KEY = "AIzaSyCwayqO2b2INwEVi8dRILQGprDtnM08Tk4";
// const YOUTUBE_CHANNEL = "UCgTpx0LixXf_SPC3NiHJcxw";

const MAX_COUNTER = 16;

function App() {

    // Getters & Setters for subscriber counter
    const [subscribers, setSubscribers] = useState(0);

    // Getters & Setters for loop
    const [loops, setLoop] = useState(0);

    // Counter for next refresh
    const [counter, setCounter] = useState(MAX_COUNTER);

    // Fetch counter from YouTube API
    const fetchCounterAPI = async () => {
        try {
            //let result = await fetch(`https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${YOUTUBE_CHANNEL}&key=${API_KEY}`);
            //let resultJson = await result.json();

            let result = await fetch(`https://youtube-counter-api.vercel.app/getFollowersCount?KEY=PWDCounterKEY`);
            let resultJson = await result.json();

            const subscriberCount = resultJson.followersCounter;
            setLoop(loops + 1);

            // console.log('Get subscribers & update loop counter');
            return { success: true, data: subscriberCount };
        } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
            return { success: false, data : [] };
        }
    }

    // Effect to load news async
    useEffect(() => {
        (async () => {

            let res = await fetchCounterAPI();
            if (res.success) {
                setSubscribers(res.data);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if ( counter > 0) {
                setTimeout(() => setCounter(counter - 1), 1000)
            } else {

                setCounter(MAX_COUNTER);

                // console.log('Relaunch counter');
                let res = await fetchCounterAPI();
                if (res.success) {
                    setSubscribers(res.data);
                }
            }
        })();
    }, [counter]);

    /*
    useEffect(() => {
        (async () => {

            if (loops > 0) {
                console.clear();
                console.log('Launch a new loop');

                setTimeout(async () => {
                    // Most recent value

                    let res = await fetchCounterAPI();
                    if (res.success) {
                        setSubscribers(res.data);
                    }

                }, 20000);
            }
        })();
    }, [loops]);*/


    /*
                        <div className="one">
                        <main className="leaderboard__profiles">
                            <article className="leaderboard__profile">
                                <img src="https://randomuser.me/api/portraits/men/32.jpg" alt="Mark Zuckerberg"
                                     className="leaderboard__picture" />
                                <span className="leaderboard__name">Mark Zuckerberg</span>
                            </article>

                            <article className="leaderboard__profile">
                                <img src="https://randomuser.me/api/portraits/men/97.jpg" alt="Dustin Moskovitz"
                                     className="leaderboard__picture" />
                                <span className="leaderboard__name">Dustin Moskovitz</span>
                            </article>

                            <article className="leaderboard__profile">
                                <img src="https://randomuser.me/api/portraits/women/17.jpg" alt="Elizabeth Holmes"
                                     className="leaderboard__picture" />
                                <span className="leaderboard__name">Elizabeth Holmes</span>
                            </article>

                            <article className="leaderboard__profile">
                                <img src="https://randomuser.me/api/portraits/men/37.jpg" alt="Evan Spiegel"
                                     className="leaderboard__picture" />
                                <span className="leaderboard__name">Evan Spiegel</span>
                            </article>

                            <article className="leaderboard__profile">
                                <img src="https://randomuser.me/api/portraits/men/97.jpg" alt="Dustin Moskovitz"
                                     className="leaderboard__picture" />
                                <span className="leaderboard__name">Dustin Moskovitz</span>
                            </article>
                        </main>
                    </div><p>Abonnez-vous pour apparaître ici !</p>
     */

    return (
        <div className="App">
            <div className="App-counter">
                {counter - 1}
            </div>
            <header className="App-header an">
                <img src={"/images/Logo.png"} className="App-logo" alt="logo" />
            </header>

            <article className="leaderboard">
                <div className="box">
                    <div className="tree">
                        <div className="counter">
                            {subscribers}
                        </div>
                        <p> Rejoignez notre communauté sur les réseaux sociaux pour échanger avec d'autres passionnés et rester à jour sur nos dernières vidéos et actualités.</p>

                    </div>
                    <div className="two">
                        <img src={"/images/qr-code.png"} className="App-qr-code" alt="QR-Code" />
                    </div>
                </div>
            </article>
        </div>
    );
}

export default App;
